/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AbstractServerInteractionService,
  combineHeadersOptions, commonErrorHandlingDisabled
} from '../abstract/abstract.server.interaction.service';

import { Observable, catchError, of } from 'rxjs';
import {
  IProfileInfoUpdateModel,
  IProfileOptionsResponse,
  IRotProfileModel
} from './interfaces';
import { AppService } from '../../../../app.service';

@Injectable()
export class ProfileService extends AbstractServerInteractionService {
  protected get url(): {
    options: Function,
    contactInfo: Function,
    profileInfo: string,
    piiInfoAvailability: string
    piiInfo: string,
    emergencyContacts: Function
  } {
    return this.appService.getApiUrls().profile;
  }

  constructor(protected appService: AppService,
              protected http: HttpClient) {
    super();
  }

  public getOptions(id: number): Observable<IProfileOptionsResponse | any> {
    return this.http.get(this.url.options(id));
  }

  public getRecruitProfile(): Observable<IRotProfileModel | any> {
    return this.http.get(this.url.profileInfo);
  }

  public sendProfileFormData(id, data: IProfileInfoUpdateModel) {
    return this.http.put(
      this.url.contactInfo(id),
      data,
      {headers: combineHeadersOptions(commonErrorHandlingDisabled)}
    );
  }

  public updateProfileEmergencyContacts(id, data) {
    return this.http.put(
      this.url.emergencyContacts(id),
      data,
      {headers: combineHeadersOptions(commonErrorHandlingDisabled)}
    );
  }

  public checkPiiInfoAvailability(): Observable<IRotProfileModel | any> {
    return this.http.get(this.url.piiInfoAvailability, {headers: combineHeadersOptions(commonErrorHandlingDisabled)})
      .pipe(catchError((error) => {
        return of(error);
      }));
  }

  public sendPiiInfoData(formData) {
    return this.http.post(this.url.piiInfo, formData);
  }
}
